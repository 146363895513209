<template>
  <div>
    <div class="w100">
      <div class="timeline">
        <div class="timeline-item timeline-item-bn">
          <div :class="iconClass">
            <i :class="iconType"></i>
          </div>
          <div class="w100">
            <div class="w100">
              <ul class="activity-types675765">
                <li :class="{ activelink767: type === 'Clinic' }" @click="setType('Clinic')">Clinic</li>
                <li :class="{ activelink767: type === 'Phone Call' }" @click="setType('Phone Call')">Phone Call</li>
                <li :class="{ activelink767: type === 'Whatsapp' }" @click="setType('Whatsapp')">Whatsapp</li>
              </ul>
              <div class="wrap-input-message">
                <textarea class="form-control" placeholder="Things to do...." rows="3" v-model="message"></textarea>
                <button class="save-tak-23" @click="saveNote"><i class="bi bi-send-fill"></i> Save</button>
              </div>
            </div>
          </div>
        </div>
        <div v-for="note in notes" :key="note.id" class="timeline-item">
          <div :class="{'icon': true, 'whatsapp-c-1': note.type === 'Whatsapp', 'call-c-1': note.type === 'Phone Call', 'general-c-1': note.type === 'Clinic'}">
            <i :class="{'bi': true, 'bi-chat-dots-fill': note.type === 'Clinic', 'bi-whatsapp': note.type === 'Whatsapp', 'bi-telephone-inbound': note.type === 'Phone Call'}"></i>
          </div>
          <div class="fo-card-item">
            <small class="text-muted">{{ formatDate(note.created_at) }}</small>
            <p>{{ note.message }}</p>
            <div class="wrap-actions-notes">
              <a href="#" class="edit-flow-23" @click="openEditModal(note)" title="Edit Note"><i class="bi bi-pencil"></i></a>
              <a href="#" class="edit-flow-23" @click="openDeleteModal(note.id)" title="Delete Note"><i class="bi bi-trash"></i></a>
            </div>
          
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Note Modal -->
    <div class="modal fade" id="editNoteModal" tabindex="-1" aria-labelledby="editNoteModalLabel" aria-hidden="true" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editNoteModalLabel">Edit Note</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <textarea class="form-control" v-model="editMessage" rows="3"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="updateNote">Save changes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirm Delete Modal -->
    <div class="modal fade" id="confirmDeleteModal" tabindex="-1" aria-labelledby="confirmDeleteModalLabel" aria-hidden="true" ref="deleteModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmDeleteModalLabel">Confirm Delete</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete this note?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" @click="deleteNote">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import API from '@/services/api';
import { Modal } from 'bootstrap'; // Import Bootstrap's Modal

export default {
  name: "NotesPage",
  props: {
    patientId: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    const notes = ref([]);
    const type = ref('Clinic');
    const message = ref('');
    const editMessage = ref('');
    const noteToEdit = ref(null);
    const noteToDelete = ref(null);

    const editModal = ref(null);
    const deleteModal = ref(null);

    const fetchNotes = async () => {
      try {
        const response = await API.getNotes(props.patientId);
        notes.value = response.data;
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    const saveNote = async () => {
      try {
        const response = await API.saveNote(props.patientId, { type: type.value, message: message.value });
        notes.value.unshift(response.data.note); // Add the new note to the beginning of the array
        message.value = '';
      } catch (error) {
        console.error('Error saving note:', error);
      }
    };

    const openEditModal = (note) => {
      noteToEdit.value = note;
      editMessage.value = note.message;
      const modalInstance = new Modal(editModal.value);
      modalInstance.show();
    };

    const updateNote = async () => {
      try {
        const response = await API.updateNote(noteToEdit.value.id, { type: noteToEdit.value.type, message: editMessage.value });
        Object.assign(noteToEdit.value, response.data.note);
        noteToEdit.value = null;
        const modalInstance = Modal.getInstance(editModal.value);
        modalInstance.hide();
      } catch (error) {
        console.error('Error updating note:', error);
      }
    };

    const openDeleteModal = (noteId) => {
      noteToDelete.value = noteId;
      const modalInstance = new Modal(deleteModal.value);
      modalInstance.show();
    };

    const deleteNote = async () => {
      try {
        await API.deleteNote(noteToDelete.value);
        notes.value = notes.value.filter(note => note.id !== noteToDelete.value);
        noteToDelete.value = null;
        const modalInstance = Modal.getInstance(deleteModal.value);
        modalInstance.hide();
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const iconClass = computed(() => {
      switch(type.value) {
        case 'Whatsapp':
          return 'icon whatsapp-c-1';
        case 'Phone Call':
          return 'icon call-c-1';
        default:
          return 'icon general-c-1';
      }
    });

    const iconType = computed(() => {
      switch(type.value) {
        case 'Whatsapp':
          return 'bi bi-whatsapp';
        case 'Phone Call':
          return 'bi bi-telephone-inbound';
        default:
          return 'bi bi-chat-dots-fill';
      }
    });

    const setType = (newType) => {
      type.value = newType;
    };

    onMounted(fetchNotes);

    return {
      notes,
      type,
      message,
      editMessage,
      noteToEdit,
      noteToDelete,
      saveNote,
      openEditModal,
      updateNote,
      openDeleteModal,
      deleteNote,
      formatDate,
      editModal,
      deleteModal,
      iconClass,
      iconType,
      setType
    };
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
