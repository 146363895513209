<template>
  <DashboardLayout>
    <div class="w-container mt-3">
      <div class="mt-4">
        <div class="table-sort">
          <div class="row">
            <div class="col-xl-7">
              <!-- <ul class="nav nav-tabs mb-4">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#"
                      >OSHC</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">OVHC</a>
                  </li>
                </ul> -->
              <div class="head-section border-bottom pb-3">
                <h3 class="h4">Create Membership Plan</h3>
                <router-link to="/members-list">Lead List</router-link>
              </div>

              <form class="form-style-1">
                <div class="row">
                  <!-- <div class="form-input-wrap">
                    <label for="mName" class="form-label">
                      Membership Name <span>*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="mName"
                      name="name"
                      required
                    />
                  </div> -->
                  <div class="form-input-wrap">
                    <label for="insurance_plan" class="form-label"
                      >Membership Name<span>*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      required=""
                    />
                    <!-- <select class="form-select" name="insurance_plan">
                      <option value="" selected>--Choose Plan---</option>
                    </select> -->
                  </div>
                  <div class="form-input-wrap">
                    <label for="email" class="form-label">Price</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      required=""
                    />
                  </div>
                  <div class="form-input-wrap w-100">
                    <label for="mobileNo" class="form-label"
                      >Description <span>*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="mobileNo"
                      name="mobile_no"
                      required=""
                    />
                  </div>

                  <!-- <div class="form-input-wrap">
                    <label for="mobileNo" class="form-label"
                      >Referral <span>*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="mobileNo"
                      name="mobile_no"
                      required=""
                    />
                  </div> -->
                  <!-- <div class="form-input-wrap">
                    <label for="mobileNo" class="form-label"
                      >Response <span>*</span></label
                    >
                    <select class="form-select" name="insurance_plan">
                      <option value="" selected>Excellent</option>
                      <option value="" selected>Good</option>
                      <option value="" selected>Bad</option>
                    </select>
                  </div> -->

                  <!-- <div class="form-input-wrap">
                      <label for="panCard" class="form-label"
                        >Choose Start Date</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        id="start_date"
                        name="start_date"
                      />
                    </div>
                    <div class="form-input-wrap">
                      <label for="panCard" class="form-label"
                        >Choose End Date</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        id="end_date"
                        name="end_date"
                      />
                    </div> -->

                  <div class="mt-3">
                    <input type="submit" value="Save Lead" class="p-btn" />
                    <input type="reset" value="Cancel" class="s-btn ms-3" />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-xl-5">
              <!-- <div class="plan_details">
                  <h2 class="h5">Plan Details</h2>
                  <img src="../../assets/images/aia.webp" />
                  <ul class="member-details mt-4" element-id="124">
                    <li element-id="123">
                      Plan Name <span element-id="122">Silver Plan</span>
                    </li>
                    <li element-id="121">
                      Premium <span element-id="120">12-April-1999</span>
                    </li>
                    <li element-id="119">
                      Mobile No. <span element-id="118">+91 70091XXXXX</span>
                    </li>
                    <li element-id="117">
                      Policy Start Date
                      <span element-id="116">30 March 2024</span>
                    </li>
                    <li element-id="117">
                      Policy End Date <span element-id="116">1 March 2025</span>
                    </li>
                  </ul>
                </div> -->
              <div class="video-container text-center">
                <div class="row">
                  <div class="card w-100">
                    <div class="card-body">
                      <h5 class="card-title">Silver</h5>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                      <h1>10000</h1>
                    </div>
                  </div>
                  <div class="card w-100">
                    <div class="card-body">
                      <h5 class="card-title">Gold</h5>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                      <h1>15000</h1>
                    </div>
                  </div>
                  <div class="card w-100">
                    <div class="card-body">
                      <h5 class="card-title">Platinum</h5>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                      <h1>20000</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
export default {
  name: "PlanCreation",
  components: { DashboardLayout },
};
</script>
