<template>
  <div class="width100">
    <div class="display-flex overlay-login">
      <div class="col-xl-5 col-lg-6 overlay-form">
        <form class="w100" @submit.prevent="login">
          <div class="row">
            <h1>Welcome Back! 👋</h1>
            <p>Please login to your account.</p>
            <div v-if="alertMessage" :class="alertClass" role="alert">
              <i :class="alertIcon"></i> &nbsp;{{ alertMessage }}
            </div>
            <div class="w100 mb-3 mt-3">
              <label for="email" class="form-label">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                required
                v-model="email"
                @input="validateForm"
              />
            </div>
            <div class="width100 position-relative">
              <label for="password" class="form-label">Password</label>
              <div class="input-group">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control"
                  id="password"
                  required
                  v-model="password"
                  @input="validateForm"
                />
                <span class="input-group-text" @click="togglePasswordVisibility">
                  <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                </span>
              </div>
            </div>
            <!-- Include a hidden field to store the reCAPTCHA token -->
            <input type="hidden" id="recaptchaToken" v-model="recaptchaToken" />
            <div class="mt-3">
              <button type="submit" class="login-form-btn" :disabled="!isFormValid || loading" :class="{ 'disabled-btn': !isFormValid || loading }">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="loading">&nbsp; Loading...</span>
                <span v-else>Login</span>
              </button>
            </div>
          </div>
          <div class="mt-3 text-center">
            <router-link to="/forgotpassword">Forget Password?</router-link>
          </div>
        </form>
      </div>
      <div class="overlay-left col-xl-7 col-lg-6"></div>
    </div>
  </div>
</template>

<script>
import API, { RECAPTCHA_SITE_KEY } from '@/services/api';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: "LoginPage",
  data() {
    return {
      email: '',
      password: '',
      recaptchaToken: '',
      alertMessage: '',
      alertClass: '',
      alertIcon: '',
      loading: false,
      showPassword: false,
      isFormValid: false,
    };
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    return { router, route };
  },
  mounted() {
    const message = this.route.query.message;
    if (message) {
      this.showAlert(message, 'alert-success', 'bi bi-check-circle-fill');
    }
  },
  methods: {
  async login() {
    this.loading = true;
    try {
      const recaptchaToken = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'login' });
      const response = await API.login({
        email: this.email,
        password: this.password,
        recaptchaToken: recaptchaToken,
      });

      console.log('API Response:', response);

      // Store user details in local storage
      const { name, email, phone, user_id, role } = response.data.user;
      const { token } = response.data;
      const expires_at = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getTime(); // 24 hours from now

      localStorage.setItem('user_id', user_id);
      localStorage.setItem('email', email);
      localStorage.setItem('token', token);
      localStorage.setItem('name', name);
      localStorage.setItem('phone', phone);
      localStorage.setItem('role', role);
      localStorage.setItem('token_expiry', expires_at.toString()); // Convert to string before storing

      console.log('Stored user details in local storage.');
      console.log('Token Expiry:', expires_at);

      this.showAlert(response.data.message || 'Login successful!', 'alert-success', 'bi bi-check-circle-fill');

      // Redirect to dashboard
      console.log('Redirecting to dashboard');
      this.router.push('/dashboard');
    } catch (error) {
      console.log('Login error:', error);
      this.showAlert(
        error.response?.data.message || 'Login failed, please try again.',
        'alert-danger',
        'bi bi-exclamation-triangle-fill'
      );
    } finally {
      this.loading = false;
    }
  },
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  },
  showAlert(message, alertClass, alertIcon) {
    this.alertMessage = message;
    this.alertClass = `alert ${alertClass} d-flex align-items-center`;
    this.alertIcon = alertIcon;
    setTimeout(() => {
      this.alertMessage = '';
    }, 5000);
  },
  validateForm() {
    this.isFormValid = this.email.length > 0 && this.password.length > 0;
  },
}
};
</script>

<style scoped>
.spinner-border {
  width: 1rem;
  height: 1rem;
}

.disabled-btn {
  cursor: not-allowed;
}
</style>
