<template>
  <DashboardLayout>
    <div class="w-container mt-3">
      <div class="mt-4">
        <div class="table-sort">
          <div class="row">
            <div class="col-xl-8">
              <div class="head-section border-bottom pb-3">
                <h3 class="h4">Add New Patient</h3>
                <router-link to="/members-list">Patient List</router-link>
              </div>

              <form ref="patientForm" class="form-style-1 needs-validation" @submit.prevent="validateForm" novalidate>
                <div class="row">
                  <div class="col-md-4">
                    <label for="patientName" class="form-label">
                      Patient Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="patientName"
                      name="patientName"
                      v-model="form.patientName"
                      required
                    />
                    <div class="invalid-feedback">
                      Please enter the patient's name.
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="fatherName" class="form-label">
                      Father Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="fatherName"
                      name="fatherName"
                      v-model="form.fatherName"
                      required
                    />
                    <div class="invalid-feedback">
                      Please enter the father's name.
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="phoneNumber" class="form-label">
                      Phone Number <span>*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      v-model="form.phoneNumber"
                      required
                      minlength="10"
                      maxlength="10"
                    />
                    <div class="invalid-feedback">
                      Phone number must be 10 digits.
                    </div>
                  </div>
                  <div class="col-md-3 mt-3">
                    <label for="dob" class="form-label">
                     Date of Birth
                    </label>
                    <input
                    type="date"
                    class="form-control"
                    id="dob"
                    name="dob"
                    v-model="form.dob"
                    @change="calculateAge"
                    required
                  />
                  <div class="invalid-feedback">
                    Please enter the date of birth.
                  </div>
                  </div>
                  <div class="sword-input mt-3">
                    <label for="age" class="form-label">
                      Age <span>*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="age"
                      name="age"
                      v-model="form.age"
                      required
                    />
                    <div class="invalid-feedback">
                      Please enter the age.
                    </div>
                  </div>

                  <div class="width-150px mt-3">
                    <label for="gender" class="form-label">
                      Gender <span>*</span>
                    </label>
                    <select id="gender" name="gender" class="form-select" v-model="form.gender" required>
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    <div class="invalid-feedback">Please select a gender.</div>
                  </div>            

                  <div class="mt-3 sword-input">
                    <label for="smoke" class="form-label">
                      Smoking
                    </label>
                    <select id="smoke" class="form-select" name="smoke" v-model="form.smoke">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                  <div class="mt-3 sword-input">
                    <label for="alcohol" class="form-label">
                      Alcohol
                    </label>
                    <select id="alcohol" class="form-select" name="alcohol" v-model="form.alcohol">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                  <div class="mt-3 sword-input">
                    <label for="allergy" class="form-label">
                      Allergies
                    </label>
                    <select id="allergy" class="form-select" name="allergy" v-model="form.allergy">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>

                  <!-- Conditional rendering of the allergy type input -->
                  <div v-if="form.allergy === 'Yes'" class="col-12 mt-3">
                    <label for="typeOfAllergy" class="form-label">
                      What Type of Allergy? <span>*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="typeOfAllergy"
                      name="typeOfAllergy"
                      v-model="form.typeOfAllergy"
                      required
                      spellcheck="false"
                    />
                    <div class="invalid-feedback">Please specify the type of allergy.</div>
                  </div>

                  <div class="col-12 mt-3">
                    <label for="address" class="form-label">
                      Address <span>*</span>
                    </label>
                    <textarea id="address" name="address" class="form-control" v-model="form.address" required></textarea>
                    <div class="invalid-feedback">Please enter the address.</div>
                  </div>

                  <div class="mt-3">
                    <button class="p-btn" type="submit">
                      <span v-if="loading">Loading...</span>
                      <span v-else>Save Patient Details</span>
                    </button>&nbsp;
                    <!-- <button class="s-btn" type="submit">
                      <span v-if="loading">Loading...</span>
                      <span v-else>Save &amp; Next</span>
                    </button> -->
                    <!-- <button type="reset" class="s-btn ms-3" @click="resetForm">Cancel</button> -->
                  </div>
                </div>
              </form>
            </div>
            <div class="col-xl-4">
              <div class="image-container text-center">
                <img src="../../assets/images/opd.jpg" alt="opd" />
              </div>
            </div>
          </div>
          <p class="mt-3 text-gray-light">
            Note: Fields marked with <span class="color-red">*</span> are mandatory.
          </p>
        </div>
      </div>
    </div>

    <!-- Toast for showing messages -->
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" ref="toast">
      <div class="toast-header">
        <strong class="me-auto">Message</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body" ref="toastBody">
        <!-- Message will be dynamically inserted here -->
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import API from '../../services/api'; // Assuming you have your API services here
import { Toast } from 'bootstrap'; // Import Bootstrap JavaScript functionality

export default {
  name: "AddNewPatient",
  components: { DashboardLayout },
  data() {
    return {
      form: {
        patientName: '',
        fatherName: '',
        phoneNumber: '',
        dob: '',
        age: '',
        gender: '',
        smoke: 'No',
        alcohol: 'No',
        allergy: 'No',
        typeOfAllergy: '',
        address: '',
        email: '',
      },
      loading: false,
      allergy: 'No' // Default value for allergy
    };
  },
  methods: {
    calculateAge() {
      if (this.form.dob) {
        const dob = new Date(this.form.dob);
        const ageDifMs = Date.now() - dob.getTime();
        const ageDate = new Date(ageDifMs); // milliseconds from epoch
        this.form.age = Math.abs(ageDate.getUTCFullYear() - 1970);
      }
    },
    async submitForm() {
      this.loading = true;
      try {
        // Fetch the doctor_id from localStorage and add it to the form data
        const doctorId = localStorage.getItem('user_id');
        const formData = { ...this.form, doctor_id: doctorId };

        const response = await API.savePatientDetails(formData);
        this.showToast(response.data.message);
        this.resetForm();
      } catch (error) {
        this.showToast(error.response.data.message || 'Error registering patient');
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      const form = this.$refs.patientForm;
      if (form.checkValidity() === false) {
        form.classList.add('was-validated');
        return;
      }
      form.classList.remove('was-validated');
      this.submitForm();
    },
    resetForm() {
      this.form = {
        patientName: '',
        fatherName: '',
        phoneNumber: '',
        dob: '',
        age: '',
        gender: '',
        smoke: 'No',
        alcohol: 'No',
        allergy: 'No',
        typeOfAllergy: '',
        address: '',
        email: '',
      };
      this.allergy = 'No';
      const form = this.$refs.patientForm;
      form.classList.remove('was-validated');
    },
    showToast(message) {
      this.$refs.toastBody.textContent = message;
      const toast = new Toast(this.$refs.toast);
      toast.show();
    }
  },
  mounted() {
    // Bootstrap form validation
    (function() {
      'use strict';
      window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        Array.prototype.filter.call(forms, function(form) {
          form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
          }, false);
        });
      }, false);
    })();
  }
};
</script>

<style>
/* Add any additional styles here */
</style>
