<template>
    <div class="width100">
      <div class="display-flex overlay-login">
        <div class="col-xl-5 col-lg-6 overlay-form">
          <form class="w100" @submit.prevent="sendResetLink">
            <div class="row">
              <h1>Forgot Password 👋</h1>
              <p>Please enter your registered email address</p>
              <div v-if="alertMessage" :class="alertClass" role="alert">
                <i :class="alertIcon"></i>&nbsp; {{ alertMessage }}
              </div>
              <div class="w100 mb-3 mt-3">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  required
                  v-model="email"
                  @input="validateForm"
                />
              </div>
    
              <!-- Include a hidden field to store the reCAPTCHA token -->
              <input type="hidden" id="recaptchaToken" v-model="recaptchaToken" />
              <div class="mt-3">
                <button type="submit" class="login-form-btn" :disabled="!isFormValid || loading" :class="{ 'disabled-btn': !isFormValid || loading }">
                  <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-if="loading">&nbsp; Loading...</span>
                  <span v-else>Send Reset Link</span>
                </button>
              </div>
            </div>            
          </form>
        </div>
        <div class="overlay-left col-xl-7 col-lg-6"></div>
      </div>
    </div>
  </template>
  
  <script>
  import API, { RECAPTCHA_SITE_KEY } from '@/services/api';
  import Swal from 'sweetalert2';
  import 'bootstrap-icons/font/bootstrap-icons.css';
  import { useRouter } from 'vue-router';
  
  export default {
    name: "ForgotPassword",
    data() {
      return {
        email: '',
        recaptchaToken: '',
        alertMessage: '',
        alertClass: '',
        alertIcon: '',
        loading: false, // Add loading state
        isFormValid: false, // Add form validation state
      };
    },
    setup() {
      const router = useRouter();
      return { router };
    },
    methods: {
      async sendResetLink() {
        this.loading = true; // Set loading to true when sending reset link starts
        try {
          const recaptchaToken = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'forgot_password' });
          const response = await API.forgotPassword({
            email: this.email,
            recaptchaToken: recaptchaToken,
          });
  
          // Example: Log the response
          console.log('API Response:', response);
  
          const successMessage = response.data.message || 'Reset link sent successfully!';
        //   this.showAlert(successMessage, 'alert-success', 'bi bi-check-circle-fill');
          this.clearForm(); // Clear the email input field
          
          if (successMessage === 'Reset link sent to your email address.') {
            this.showSweetAlert();
          }
        } catch (error) {
          let errorMessage = 'Failed to send reset link, please try again.';
          if (error.response && error.response.data) {
            const errorData = error.response.data;
            if (typeof errorData === 'object') {
              errorMessage = Object.values(errorData).flat().join(' ');
            } else {
              errorMessage = errorData;
            }
          }
          this.showAlert(
            errorMessage,
            'alert-danger',
            'bi bi-exclamation-triangle-fill'
          );
        } finally {
          this.loading = false; // Set loading to false when sending reset link completes
        }
      },
      showAlert(message, alertClass, alertIcon) {
        this.alertMessage = message;
        this.alertClass = `alert ${alertClass} d-flex align-items-center`;
        this.alertIcon = alertIcon;
        setTimeout(() => {
          this.alertMessage = '';
        }, 10000); // Set timeout to 5000 milliseconds (5 seconds)
      },
      showSweetAlert() {
        Swal.fire({
          title: 'Success!',
          text: 'Reset link sent to your email address.',
          icon: 'success',
          confirmButtonText: '<i class="bi bi-envelope"></i> Check Gmail',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.open('https://mail.google.com', '_blank');
            this.router.push('/');
          }
        });
      },
      validateForm() {
        this.isFormValid = this.email.length > 0; // Validate the form based on email input
      },
      clearForm() {
        this.$nextTick(() => {
          this.email = ''; // Clear the email input field
          this.isFormValid = false; // Reset form validation state
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
  
  .disabled-btn {
    cursor: not-allowed;
  }
  </style>
  