import axios from 'axios';

// Define the base URL
const BASE_URL = 'https://amaziofitapi.amaziocraft.com';

// Define the reCAPTCHA site key
export const RECAPTCHA_SITE_KEY = '6LeBhPgpAAAAAIIymfkgDDCRzvQIs_u5qIS7yu5d'; // Test site key for localhost

// Create an Axios instance with the base URL
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Add a request interceptor to include the token in every request

apiClient.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    try {
      const response = await axios.get(`${BASE_URL}/api/check-session`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status !== 200) {
        throw new Error('Session is invalid');
      }
    } catch (error) {
      localStorage.clear();
      window.location.href = '/';
      return Promise.reject(error);
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
const API = {
  // Authentication endpoints
  login: (credentials) => apiClient.post('/api/login', credentials),
  checkSession: () => apiClient.get('/api/check-session'),
  resetPassword: (data, permToken) =>
    apiClient.post('/api/resetpassword', data, {
      headers: {
        'perm': permToken,
      },
    }),
  forgotPassword: (data) => apiClient.post('/api/forgot-password', data),
  logout: () => apiClient.post('/api/logout'),

  // Patient endpoints
  savePatientDetails: (patient) => apiClient.post('/api/save-patient-details', patient),
  updatePatientDetails: (patient_id, patient) => apiClient.put(`/api/patients/${patient_id}`, patient),
  deletePatientDetails: (patient_id) => apiClient.delete(`/api/patients/${patient_id}`),
  getPatientDetails: (patient_id) => apiClient.get(`/api/patients/${patient_id}`),
  getAllPatients: () => apiClient.get('/api/patients'),

  // Notes endpoints
  getNotes: (patientId) => apiClient.get(`/api/notes-list/${patientId}`),
  saveNote: (patientId, note) => apiClient.post(`/api/save-notes/${patientId}`, note),
  updateNote: (noteId, note) => apiClient.put(`/api/update-notes/${noteId}`, note),
  deleteNote: (noteId) => apiClient.delete(`/api/delete-notes/${noteId}`)
};

export default API;
