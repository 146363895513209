<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <h2 class="h4 border-bottom pb-3">Staff List</h2>
      <div class="mt-4">
        <div class="display-flex mb-2">
          <div class="flex-1">
            <ul class="level-tabs">
              <li>
                <span class="badge rounded-pill active-level"
                  >Active Members</span
                >
              </li>
              <li>
                <span class="badge rounded-pill bg-secondary"
                  >Non-Active Members</span
                >
              </li>
            </ul>
          </div>
          <div>
            <input
              type="search"
              class="search-form-control"
              placeholder="Search..."
              v-model="searchQuery"
            />
            <button class="export-btn ms-3" @click="exportAsPdf">
              Export as PDF <span class="material-icons"> print </span>
            </button>
          </div>
        </div>

        <div class="table-sort">
          <table class="table" id="pdf-table">
            <thead>
              <tr>
                <th scope="col" @click="sort('name')">
                  Name
                  <span class="material-icons">swap_vert</span>
                </th>
                <th scope="col" @click="sort('name')">
                  Fathers Name
                  <span class="material-icons">swap_vert</span>
                </th>
                <!-- <th scope="col" @click="sort('name')">
                    Father Name
                    <span class="material-icons">swap_vert</span>
                  </th> -->
                <!-- <th scope="col" @click="sort('name')">
                    Upline
                    <span class="material-icons">swap_vert</span>
                  </th> -->
                <!-- <th scope="col" @click="sort('name')">
                    DS
                    <span class="material-icons">swap_vert</span>
                  </th> -->
                <th scope="col" @click="sort('phone')">
                  Mobile No <span class="material-icons"> swap_vert </span>
                </th>
                <th scope="col" @click="sort('doj')">
                  D.O.B
                  <span class="material-icons"> swap_vert </span>
                </th>
                <th scope="col" @click="sort('doj')">
                  Designation <span class="material-icons"> swap_vert </span>
                </th>

                <th scope="col" @click="sort('doj')">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr>
                <td>1</td>
                <td>Aman</td>
                <td>+91 7009123XXX</td>
                <td>Monthly</td>
                <td>Vijay</td>
                <td>Good</td>
                <td>Paid</td>
              </tr> -->
            </tbody>
          </table>
          <nav aria-label="Page navigation example ">
            <ul class="pagination justify-content-end">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="changePage(currentPage - 1)"
                  tabindex="-1"
                  aria-disabled="currentPage === 1"
                  >Previous</a
                >
              </li>
              <!-- Dynamically generate page numbers based on totalPages -->
              <li
                class="page-item"
                :class="{ active_page: n === currentPage }"
                v-for="n in totalPages"
                :key="n"
              >
                <a class="page-link" href="#" @click.prevent="changePage(n)">{{
                  n
                }}</a>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="changePage(currentPage + 1)"
                  >Next</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import { ref, computed } from "vue";
import { userList } from "@/services/api";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
export default {
  name: "MemberList",
  components: { DashboardLayout },
  setup() {
    const currentPage = ref(1);
    const itemsPerPage = ref(2);
    const searchQuery = ref("");
    const sortKey = ref("");
    const sortAsc = ref(true);
    const users = ref(userList.value);
    const paginatedUsers = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredUsers.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredUsers.value.length / itemsPerPage.value);
    });

    function changePage(page) {
      if (page < 1) {
        currentPage.value = 1;
      } else if (page > totalPages.value) {
        currentPage.value = totalPages.value;
      } else {
        currentPage.value = page;
      }
    }

    async function exportAsPdf() {
      const pdf = new jsPDF();

      const element = document.getElementById("pdf-table"); // Ensure your table has this ID
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("table.pdf");
    }

    const sortedUsers = computed(() => {
      return [...users.value].sort((a, b) => {
        if (sortKey.value) {
          const aValue = a[sortKey.value];
          const bValue = b[sortKey.value];

          if (sortAsc.value) {
            return aValue.localeCompare(bValue, undefined, { numeric: true });
          } else {
            return bValue.localeCompare(aValue, undefined, { numeric: true });
          }
        }
        return 0;
      });
    });

    const filteredUsers = computed(() => {
      return sortedUsers.value.filter((user) =>
        Object.values(user).some((field) =>
          field
            .toString()
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        )
      );
    });

    function sort(key) {
      if (sortKey.value === key) {
        sortAsc.value = !sortAsc.value;
      } else {
        sortAsc.value = true;
        sortKey.value = key;
      }
    }

    return {
      users,
      searchQuery,
      filteredUsers,
      sort,
      exportAsPdf,
      currentPage,
      itemsPerPage,
      totalPages,
      paginatedUsers,
      changePage,
    };
  },
};
</script>
