<template>
  <div class="width100">
    <div class="display-flex overlay-login">
      <div class="col-xl-5 col-lg-6 overlay-form">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <h1>Forgot Password 👋</h1>

            <div class="width100 position-relative">
              <label for="password" class="form-label">Password</label>
              <div class="input-group">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control"
                  id="password"
                  required
                  v-model="password"
                  @input="validatePassword"
                />
                <span class="input-group-text" @click="togglePasswordVisibility">
                  <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                </span>
              </div>
            </div>

            <div class="width100 position-relative mt-3">
              <label for="password_confirmation" class="form-label">Confirm Password</label>
              <div class="input-group">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control"
                  id="password_confirmation"
                  required
                  v-model="password_confirmation"
                  @input="validatePassword"
                />
                <span class="input-group-text" @click="togglePasswordVisibility">
                  <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                </span>
              </div>
            </div>

            <div class="password-instructions mt-3">
              <p>Password must include:</p>
              <ul>
                <li :class="{ valid: validations.length, invalid: !validations.length }">At least 8 characters</li>
                <li :class="{ valid: validations.uppercase, invalid: !validations.uppercase }">At least one uppercase letter (A-Z)</li>
                <li :class="{ valid: validations.lowercase, invalid: !validations.lowercase }">At least one lowercase letter (a-z)</li>
                <li :class="{ valid: validations.special, invalid: !validations.special }">At least one special character (!@#$%^&*)</li>
                <li :class="{ valid: validations.match, invalid: !validations.match }">Password confirmation match</li>
              </ul>
            </div>

            <div class="mt-4">
              <button
                type="submit"
                class="login-form-btn"
                :disabled="!isPasswordValid || loading"
                :style="{ cursor: !isPasswordValid ? 'not-allowed' : 'pointer' }"
              >
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="loading">&nbsp; Loading...</span>
                <span v-else>Save Password</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="overlay-left col-xl-7 col-lg-6"></div>
    </div>
  </div>
</template>

<script>
import API from '@/services/api';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      password: '',
      password_confirmation: '',
      loading: false,
      perm: '', // Initialize perm variable to store the token
      showPassword: false,
      validations: {
        length: false,
        uppercase: false,
        lowercase: false,
        special: false,
        match: false
      }
    };
  },
  mounted() {
    // Fetch and display perm token when component is mounted
    this.fetchPermToken();
  },
  computed: {
    isPasswordValid() {
      return (
        this.validations.length &&
        this.validations.uppercase &&
        this.validations.lowercase &&
        this.validations.special &&
        this.validations.match
      );
    }
  },
  methods: {
    fetchPermToken() {
      // Get the current URL
      let currentUrl = window.location.href;

      // Find the index of "/resetpassword/"
      let resetPasswordIndex = currentUrl.indexOf("/resetpassword/");

      // Extract the perm value from the URL
      if (resetPasswordIndex !== -1) {
        let perm = currentUrl.substring(resetPasswordIndex + "/resetpassword/".length);

        // Decode the perm value (it appears to be URL-encoded)
        perm = decodeURIComponent(perm);

        console.log('perm:', perm);

        // Set perm value to data property
        this.perm = perm;
      } else {
        console.error('Invalid reset password URL format');
      }
    },
    validatePassword() {
      const password = this.password;
      this.validations.length = password.length >= 8;
      this.validations.uppercase = /[A-Z]/.test(password);
      this.validations.lowercase = /[a-z]/.test(password);
      this.validations.special = /[!@#$%^&*]/.test(password);
      this.validations.match = this.password === this.password_confirmation;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async handleSubmit() {
      if (!this.isPasswordValid) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Please ensure all password requirements are met and passwords match',
          confirmButtonText: 'OK'
        });
        return;
      }

      this.loading = true;

      try {
        const data = {
          token: this.perm,
          password: this.password,
          password_confirmation: this.password_confirmation
        };

        const response = await API.resetPassword(data, this.perm);
        console.log('Password reset successful:', response.data);

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonText: 'Login Account',
          timer: 5000,
          timerProgressBar: true,
          willClose: () => {
            window.location.href = '/';
          }
        }).then((result) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            window.location.href = '/';
          }
        });

        this.password = '';
        this.password_confirmation = '';
      } catch (error) {
        console.error('Password reset failed:', error.response ? error.response.data : error.message);

        let errorMessage = 'An error occurred';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (error.message) {
          errorMessage = error.message;
        }

        if (errorMessage === "Session expired. Please generate the link again.") {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
            confirmButtonText: 'Generate Link',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
            confirmButtonText: 'OK'
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
/* Add your custom styles here */
.password-instructions {
  font-size: 0.9em;
  color: #555;
}

.password-instructions ul {
  padding-left: 1.2em;
}

.password-instructions ul li {
  list-style-type: disc;
}

.valid {
  color: green;
}

.invalid {
  color: red;
}

.login-form-btn:disabled {
  cursor: not-allowed;
}

.input-group-text {
  cursor: pointer;
}
</style>
