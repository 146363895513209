<template>
  <DashboardLayout>
    <div v-if="loading" class="text-center mt-5">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else class="w-container mt-3 mb-5 bg-light-section">
      
      <div style="position: sticky;top:0px;background:#fff;z-index:33;">
        <div class="profile-card-circle">
          <div class="user-tag">
            <div class="avatar">
              <img src='../../assets/images/male.jpg' />
            </div>
            <div class="contact-info">
              <h5 class="name">
                {{ user.patientName || '-' }} <i class="bi bi-patch-check-fill"></i>
              </h5>
              <p class="details">{{ user.phoneNumber || '-' }} | Last Visit : {{ formatDate(user.last_visit) }} </p>
            </div>
          </div>
          <div>
            <button
              class="export-btn ms-3"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              Edit Details
              <span class="material-icons"> drive_file_rename_outline </span>
            </button>
            <button
              class="export-btn ms-3"
              id="print_OPD_receipt"
              @click="printOpdReceipt"
            >
              <span class="material-icons"> print </span>
            </button>
          </div>
        </div>
      </div>

      <nav class="tab-container mt-3">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link"
            :class="{ active: activeTab === 'ProfileOverview' }"
            @click="activeTab = 'ProfileOverview'"
          >
            Overview
          </button>
                <!-- <button
            class="nav-link"
            :class="{ active: activeTab === 'OpdReceipt' }"
            @click="activeTab = 'OpdReceipt'"
          >
            OPD Receipt
          </button>  -->
          <!-- <button
            class="nav-link"
            :class="{ active: activeTab === 'OpdReceipt' }"
            @click="activeTab = 'OpdReceipt'"
          >
          Perception
          </button> -->
          <button
            class="nav-link"
            :class="{ active: activeTab === 'Payments' }"
            @click="activeTab = 'Payments'"
          >
            Payments History
          </button>
          <button
            class="nav-link"
            :class="{ active: activeTab === 'Notes' }"
            @click="activeTab = 'Notes'"
          >
            Notes
          </button>
        </div>
      </nav>

      <div class="tab-container mt-4 mx-auto">
        <component :is="currentTabComponent" :user="user" :patientId="user.patient_id" :formatDate="formatDate"></component>
      </div>

      <!-- OpdReceipt Component always present in the DOM -->
      <OpdReceipt ref="opdReceiptComponent" :user="user" :formatDate="formatDate" style="display: none;"></OpdReceipt>
    </div>

    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel">Edit Patient Details</h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="width100">
          <div v-if="apiMessage" :class="`mt-3 alert ${apiMessageClass}`">{{ apiMessage }}</div>

          <form class="form-style-1" @submit.prevent="updatePatientDetails">
            <div class="row">
              <div class="form-input-wrap-2">
                <label for="mName" class="form-label">Patient Name <span>*</span></label>
                <input type="text" class="form-control" id="mName" v-model="user.patientName" required />
              </div>
              <div class="form-input-wrap-2">
                <label for="fatherName" class="form-label">Father Name <span>*</span></label>
                <input type="text" class="form-control" id="fatherName" v-model="user.fatherName" required />
              </div>
              <div class="form-input-wrap-2">
                <label for="mobileNo" class="form-label">Phone Number <span>*</span></label>
                <input type="text" class="form-control" id="mobileNo" v-model="user.phoneNumber" required />
              </div>
              <div class="form-input-wrap-2">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email" v-model="user.email" />
              </div>
              <div class="form-input-wrap-2">
                <label for="dob" class="form-label">Date of Birth</label>
                <input type="date" class="form-control" id="dob" v-model="user.dob" @change="calculateAge" />
              </div>
              <div class="form-input-wrap-2">
                <label for="age" class="form-label">Age <span>*</span></label>
                <input type="number" class="form-control" id="age" v-model="user.age" required :readonly="!!user.dob" />
              </div>
              <div class="form-input-wrap-2">
                <label for="gender" class="form-label">Gender <span>*</span></label>
                <select class="form-control" id="gender" v-model="user.gender" required>
                  <option value="" disabled>Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div class="form-input-wrap-2">
                <label for="smoke" class="form-label">Smoking</label>
                <select class="form-control" id="smoke" v-model="user.smoke">
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              <div class="form-input-wrap-2">
                <label for="alcohol" class="form-label">Alcohol</label>
                <select class="form-control" id="alcohol" v-model="user.alcohol">
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              <div class="form-input-wrap-2">
                <label for="allergy" class="form-label">Allergies</label>
                <select class="form-control" id="allergy" v-model="user.allergy">
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              <div class="w100">
                <label for="address" class="form-label">Address <span>*</span></label>
                <textarea class="form-control" id="address" v-model="user.address" required></textarea>
              </div>
            </div>
            <button type="submit" class="btn btn-primary mt-3">Update Patient Details</button>
          </form>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { ref, computed, defineAsyncComponent } from 'vue';
import DashboardLayout from '../dashboard/DashboardLayout.vue';
import { useRoute } from 'vue-router';
import { reactive, onMounted } from 'vue';
import API from '@/services/api';
import OpdReceipt from './info/OpdReceipt.vue'; // Import the OpdReceipt component
import NotesPage from './info/NotesPage.vue'; // Import the NotesPage component

export default {
  name: 'UserDetails',
  components: { DashboardLayout, OpdReceipt, NotesPage }, // Register the OpdReceipt and NotesPage components
  setup() {
    const activeTab = ref('ProfileOverview');
    const route = useRoute();
    const loading = ref(true);
    const user = reactive({
      id: '',
      patientName: '',
      phoneNumber: '',
      email: '',
      last_visit: '',
      fatherName: '',
      dob: '',
      age: '',
      gender: '',
      smoke: '',
      alcohol: '',
      allergy: '',
      address: ''
    });
    const opdReceiptComponent = ref(null);
    const apiMessage = ref('');
    const apiMessageClass = ref('');

    const fetchUserDetails = async (userId) => {
      try {
        const response = await API.getPatientDetails(userId);
        Object.assign(user, response.data.patient); // Adjusted to match the API response structure
        loading.value = false;
      } catch (error) {
        console.error('Error fetching user details:', error);
        loading.value = false;
        apiMessage.value = 'Error fetching user details';
        apiMessageClass.value = 'alert-danger';
      }
    };

    const updatePatientDetails = async () => {
      try {
        const userId = route.params.id;
        await API.updatePatientDetails(userId, user);
        apiMessage.value = 'Patient details updated successfully';
        apiMessageClass.value = 'alert-success';
      } catch (error) {
        console.error('Error updating patient details:', error);
        apiMessage.value = 'Error updating patient details';
        apiMessageClass.value = 'alert-danger';
      }
    };

    onMounted(() => {
      const userId = route.params.id;
      fetchUserDetails(userId);
    });

    const formatDate = (dateString) => {
      if (!dateString) return '-';
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const calculateAge = () => {
      if (user.dob) {
        const birthDate = new Date(user.dob);
        const ageDifMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        user.age = Math.abs(ageDate.getUTCFullYear() - 1970);
      }
    };

    const tabComponents = {
      ProfileOverview: defineAsyncComponent(() => import('./info/ProfileOverview.vue')),
      OpdReceipt: defineAsyncComponent(() => import('./info/OpdReceipt.vue')),
      Payments: defineAsyncComponent(() => import('./info/PaymentsPage.vue')),
      Notes: NotesPage
    };

    const currentTabComponent = computed(() => {
      return tabComponents[activeTab.value];
    });

    const printOpdReceipt = () => {
      console.log('printOpdReceipt method called');
      console.log('opdReceiptComponent:', opdReceiptComponent.value);
      if (opdReceiptComponent.value && typeof opdReceiptComponent.value.printInvoice === 'function') {
        console.log('Calling printInvoice method on OpdReceipt component');
        opdReceiptComponent.value.printInvoice();
      } else {
        console.log('OpdReceipt component or printInvoice method not found');
      }
    };

    return {
      activeTab,
      currentTabComponent,
      user,
      formatDate,
      updatePatientDetails,
      loading,
      printOpdReceipt,
      opdReceiptComponent,
      calculateAge,
      apiMessage,
      apiMessageClass
    };
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
