<template>
  <div>
    <div class="text-end print-btn">
      <button class="btn btn-primary" @click="printInvoice">Print Invoice</button>
    </div>
    <div class="container mt-5" id="patient_invoice">
      <div class="card">
        <div class="card-header text-center bg-header-invoice">
          <h3>Prem Eye And Maternity Hospital</h3>
        </div>
        <div class="card-body">
          <div class="row mb-3 align-top-232">
            <div class="col-md-6 col-sm-6">
              <h5>Patient Details</h5>
              <p><strong>Name:</strong> {{ user.patientName }}</p>
              <p><strong>Father Name:</strong> {{ user.fatherName }}</p>
              <p><strong>Date of Birth:</strong> {{ formatDate(user.dob) }}</p>
              <p><strong>Age/Sex:</strong> {{ user.age }}/{{ user.gender }}</p>
              <p><strong>Address:</strong> {{ user.address }}</p>
              <p><strong>Phone No:</strong> {{ user.phoneNumber }}</p>
            </div>
            <div class="col-md-6 col-sm-6 text-end">
              <p><strong>Date:</strong> {{ formatDate(new Date()) }}</p>
              <div class="mt-3">
                <h5>Dr. Rajesh Kumar</h5>
                <p>General Physician</p>
                <p>+91 7009123XXX</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center footer-bottom">
        <p><strong>Contact Us:</strong> 123-456-7890 | info@hospital.com | www.hospital.com</p>
        <p>Hospital Address, City, State, ZIP</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpdReceipt",
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({})
    },
    formatDate: {
      type: Function,
      required: true
    }
  },
  methods: {
    printInvoice() {
      console.log('printInvoice method called');
      const printContents = document.getElementById('patient_invoice').innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  }
};
</script>

<style scoped>
#patient_invoice {
  position: relative;
  height: 297mm;
}

.print-btn {
  position: relative;
  z-index: 20;
}

.footer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important; /* Ensure colors are printed accurately */
    print-color-adjust: exact !important;
    width: 210mm;
    height: 297mm;
  }

  .card-header,
  .card-body,
  .card-footer {
    page-break-inside: avoid;
  }

  .print-btn {
    display: none;
  }

  .footer-bottom {
    position: fixed;
    bottom: 0;
  }
}
</style>
