<template>
<DashboardLayout>
  <div class="w-container">
    <h2 class="h5 mt-4 mb-2 head-tab-title">Add New Staff Member</h2>

    <div class="accordion" id="applicantAccordion">
      <!-- Basic Details Accordion Item -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingBasicDetails">
          <button class="accordion-button h4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBasicDetails" aria-expanded="true" aria-controls="collapseBasicDetails">
            <i class="bi bi-person-circle"></i> &nbsp; Personal Details
          </button>
        </h2>
        <div id="collapseBasicDetails" class="accordion-collapse collapse show" aria-labelledby="headingBasicDetails" data-bs-parent="#applicantAccordion">
          <div class="accordion-body">
            <form class="row g-3 needs-validation" novalidate>
              <div class="col-md-3">
                <label for="title" class="form-label">Title <span class="field-required">*</span></label>
                <select id="title" name="title" class="form-select" required>
                  <option value="" selected="selected">Please Select</option>
                  <option label="Mr" value="Mr">Mr</option>
                  <option label="Mrs" value="Mrs">Mrs</option>
                  <option label="Miss" value="Miss">Miss</option>
                  <option label="Ms" value="Ms">Ms</option>
                  <option label="Dr" value="Dr">Dr</option>
                  <option label="Other" value="Other">Other</option>
                </select>
                <div class="invalid-feedback">Title is required.</div>
              </div>
              <div class="col-md-3">
                <label for="name" class="form-label">Name <span class="field-required">*</span></label>
                <input type="text" class="form-control" id="name" name="name" required>
                <div class="invalid-feedback">Name field is required.</div>
              </div>
              <div class="col-md-3">
                <label for="fatherName" class="form-label">Father Name <span class="field-required">*</span></label>
                <input type="text" class="form-control" id="fatherName" name="fatherName" required>
                <div class="invalid-feedback">Father Name field is required.</div>
              </div>
              <div class="col-md-3">
                <label for="motherName" class="form-label">Mother Name <span class="field-required">*</span></label>
                <input type="text" class="form-control" id="motherName" name="motherName" required>
                <div class="invalid-feedback">Mother Name field is required.</div>
              </div>
              <div class="col-md-3">
                <label for="gender" class="form-label">Gender <span class="field-required">*</span></label>
                <select id="gender" name="gender" class="form-select" required>
                  <option value="">Please Select</option>
                  <option label="Male" value="Male">Male</option>
                  <option label="Female" value="Female">Female</option>
                  <option label="Other" value="Other">Other</option>
                </select>
                <div class="invalid-feedback">Gender field is required.</div>
              </div>
              <div class="col-md-3">
                <label for="dob" class="form-label">Date of Birth <span class="field-required">*</span></label>
                <input type="date" class="form-control" id="dob" name="dob" required>
                <div class="invalid-feedback">Date of Birth field is required.</div>
              </div>
              <div class="col-md-3">
                <label for="maritalStatus" class="form-label">Marital Status <span class="field-required">*</span></label>
                <select id="maritalStatus" name="maritalStatus" class="form-select" required @change="checkMaritalStatus">
                  <option value="">Please Select</option>
                  <option label="Unmarried" value="Unmarried">Unmarried</option>
                  <option label="Married" value="Married">Married</option>
                  <option label="In a De Facto Relationship" value="de-facto">In a De Facto Relationship</option>
                  <option label="Widowed" value="Widowed">Widowed</option>
                  <option label="Separated" value="Separated">Separated</option>
                  <option label="Divorced" value="Divorced">Divorced</option>
                </select>
                <div class="invalid-feedback">Marital Status field is required.</div>
              </div>
              <div class="col-md-3">
                <label for="aadharNumber" class="form-label">Aadhar Number <span class="field-required">*</span></label>
                <input type="text" class="form-control" id="aadharNumber" name="aadharNumber" required>
                <div class="invalid-feedback">Aadhar Number field is required.</div>
              </div>
        
              <div class="col-12">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="invalidCheck" name="invalidCheck" required>
                  <label class="form-check-label" for="invalidCheck">I have checked all the details</label>
                  <div class="invalid-feedback">You must agree before submitting.</div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-primary" type="button">Save Details</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    
      <!-- Education Details Accordion Item -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEducationDetails">
          <button class="accordion-button collapsed h4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEducationDetails" aria-expanded="false" aria-controls="collapseEducationDetails">
            <i class="bi bi-backpack3-fill"></i> &nbsp; Education Details
          </button>
        </h2>
        <div id="collapseEducationDetails" class="accordion-collapse collapse" aria-labelledby="headingEducationDetails" data-bs-parent="#applicantAccordion">
          <div class="accordion-body">
            <div class="custom-table-232">
              <table class="table">
                <thead>
                  <tr class="table-tr-head">
                    <th scope="col">Qualification</th>
                    <th scope="col">College/University</th>
                    <th scope="col">Passed year</th>
                    <th scope="col">Total Marks/CGPA</th>
                    <th scope="col">Obtain Marks/CGPA</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(qualification, index) in qualifications" :key="index">
                    <th scope="row">
                      <div class="w-100">
                        <select v-model="qualification.qualification" name="qualification" class="form-select" required>
                          <option value="">Select</option>
                          <option label="10th" value="10th">10th</option>
                          <option label="+2" value="+2">+2</option>
                        </select>
                      </div>
                    </th>
                    <td>
                      <input v-model="qualification.college" class="form-control" type="text" name="college_university" required>
                      <div class="invalid-feedback">College/University field is required.</div>
                    </td>
                    <td>
                      <input v-model="qualification.passedYear" class="form-control" type="text" name="pass_year" required>
                      <div class="invalid-feedback">Passed year field is required.</div>
                    </td>
                    <td>
                      <input v-model="qualification.totalMarks" class="form-control" type="text" name="total_marks" required>
                      <div class="invalid-feedback">Total Marks/CGPA field is required.</div>
                    </td>
                    <td>
                      <input v-model="qualification.obtainMarks" class="form-control" type="text" name="obtain_marks" required>
                      <div class="invalid-feedback">Obtain Marks/CGPA field is required.</div>
                    </td>
                    <td>
                      <span class="badge rounded-pill bg-success" @click="saveRow(index, 'qualifications')">Save</span>
                      <span class="badge rounded-pill bg-primary" @click="editRow(index, 'qualifications')">Edit</span>
                      <span class="badge rounded-pill bg-danger" @click="deleteRow(index, 'qualifications')">Delete</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="button" class="btn btn-primary" @click="addRow('qualifications')">Add New Row +</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Job Experience Accordion Item -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingJobExperience">
          <button class="accordion-button collapsed h4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJobExperience" aria-expanded="false" aria-controls="collapseJobExperience">
            <i class="bi bi-person-workspace"></i> &nbsp; Job Experience
          </button>
        </h2>
        <div id="collapseJobExperience" class="accordion-collapse collapse" aria-labelledby="headingJobExperience" data-bs-parent="#applicantAccordion">
          <div class="accordion-body">
            <div class="custom-table-232">
              <table class="table">
                <thead>
                  <tr class="table-tr-head">
                    <th scope="col">Job Title</th>
                    <th scope="col">Employment type</th>
                    <th scope="col">Company name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(experience, index) in experiences" :key="index">
                    <th scope="row">
                      <div class="w-100">
                        <input v-model="experience.jobTitle" class="form-control" type="text" name="job_title" placeholder="Ex: Receptionist" required>
                        <div class="invalid-feedback">Job Title field is required.</div>
                      </div>
                    </th>
                    <td>
                      <select v-model="experience.employmentType" name="employmentType" class="form-select" required>
                        <option value="Please select">Please select</option>
                        <option value="Full-time">Full-time</option>
                        <option value="Part-time">Part-time</option>
                        <option value="Self-employed">Self-employed</option>
                        <option value="Freelance">Freelance</option>
                        <option value="Internship">Internship</option>
                        <option value="Trainee">Trainee</option>
                      </select>
                      <div class="invalid-feedback">Employment type field is required.</div>
                    </td>
                    <td>
                      <input v-model="experience.companyName" class="form-control" type="text" name="company_name" required>
                      <div class="invalid-feedback">Company name field is required.</div>
                    </td>
                    <td>
                      <input v-model="experience.startDate" class="form-control" type="date" name="job_start_date" required>
                      <div class="invalid-feedback">Start Date field is required.</div>
                    </td>
                    <td>
                      <input v-model="experience.endDate" class="form-control" type="date" name="job_end_date" required>
                      <div class="invalid-feedback">End Date field is required.</div>
                    </td>
                    <td>
                      <span class="badge rounded-pill bg-success" @click="saveRow(index, 'experiences')">Save</span>
                      <span class="badge rounded-pill bg-primary" @click="editRow(index, 'experiences')">Edit</span>
                      <span class="badge rounded-pill bg-danger" @click="deleteRow(index, 'experiences')">Delete</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="button" class="btn btn-primary" @click="addRow('experiences')">Add New Row +</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</DashboardLayout>

</template>


<script>
import DashboardLayout from '../dashboard/DashboardLayout.vue';

export default {
  name: "AddStaff",
  components: { DashboardLayout },

  data() {
    return {
      isMarried: false,
      qualifications: [
        {
          qualification: '',
          college: '',
          passedYear: '',
          totalMarks: '',
          obtainMarks: '',
        },
      ],
      experiences: [
        {
          jobTitle: '',
          employmentType: '',
          companyName: '',
          startDate: '',
          endDate: '',
        },
        ],
    };
  },
  methods: {
    checkMaritalStatus(event) {
      this.isMarried = event.target.value === 'Married';
    },
    addRow(section) {
      if (section === 'qualifications') {
        this.qualifications.push({
          qualification: '',
          college: '',
          passedYear: '',
          totalMarks: '',
          obtainMarks: '',
        });
      } else if (section === 'experiences') {
        this.experiences.push({
          jobTitle: '',
          employmentType: '',
          companyName: '',
          startDate: '',
          endDate: '',
        });
      }
    },
    deleteRow(index, section) {
      if (section === 'qualifications') {
        this.qualifications.splice(index, 1);
      } else if (section === 'experiences') {
        this.experiences.splice(index, 1);
      }
    },
    saveRow(index, section) {
      if (section === 'qualifications') {
        console.log('Qualification saved:', this.qualifications[index]);
      } else if (section === 'experiences') {
        console.log('Experience saved:', this.experiences[index]);
      }
    },
    editRow(index, section) {
      if (section === 'qualifications') {
        console.log('Qualification edit:', this.qualifications[index]);
      } else if (section === 'experiences') {
        console.log('Experience edit:', this.experiences[index]);
      }
    },
  },
};
</script>