<template>
  <DashboardLayout>
   <div class="w-container mt-1">
      <div class="row">
          <div class="width100">
              <div class="profile-card">
                  <h1>Welcome, <span class>Dr. {{ name }}</span> 👋</h1> 
                  <p class="mb-3">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis veniam quaerat eos, neque asperiores molestiae dolor officia impedit eaque sequi.</p>
                  <div class="dash-summery mt-4">
                      <div class="card-items-bba-1 light-green">
                          <div class="card-details">
                              <span>Total Patient</span>
                              <h4>100</h4>
                          </div>
                      </div>
                      <div class="card-items-bba-1 teal-blue">
                          <div class="card-details">
                              <span>Today's Appointment</span>
                              <h4>05</h4>
                          </div>
                      </div>
                      <div class="card-items-bba-1 trust-light">
                          <div class="card-details">
                              <span>Total Collection</span>
                              <h4>₹ 10,000</h4>
                          </div>
                      </div>
                      <div class="card-items-bba-1 red-light">
                          <div class="card-details">
                              <span>Pending Amount</span>
                              <h4>₹ 5,000</h4>
                          </div>
                      </div>
                  </div>
              </div>
      
          </div>
          <div class="row mt-4">
              <div class="col-xl-7">
                  <div>
                  <div class="head-section">
                      <h3>Upcoming Appointments</h3>
                      <a href="/members-list" class="">View All</a></div>
                      <div class="table-sort">
                          <table class="table" id="pdf-table">
                              <thead>
                                  <tr>
                                      <th scope="col">Name</th>
                                      <th scope="col">Mobile No</th>
                                      <th scope="col">Appointment Date</th>
                                      <th scope="col">Medical Concern</th>
                                      <th scope="col">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>Ajay</td>
                                      <td>70090xxxxx</td>
                                      <td>23-04-2024 / 12:30 pm</td>
                                      <td>Piles</td>
                                      <td><button class="badge rounded-pill bg-secondary mt-1">Details</button></td>
                                  </tr>
                              </tbody>
                          </table>
                          
                      </div>
                      
          </div>
      </div>
          </div>
   </div>
  </div>
</DashboardLayout>             
</template>

<script>
  import DashboardLayout from "./DashboardLayout.vue";
// import { ref, computed } from 'vue';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
export default {
  name: 'MemberDashboard',
  components:{DashboardLayout},
}
</script>