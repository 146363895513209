import { createRouter, createWebHistory } from "vue-router";
import MemberList from "@/components/members/MemberList.vue";
import AdminDashboard from "@/components/dashboard/AdminDashboard.vue";
import UserDetails from "@/components/members/UserDetails.vue";
import SendNewQuotation from "@/components/members/SendNewQuotation.vue";
import MemberPayments from "@/components/payments/MemberPayments.vue";
import Login from "@/components/login/LoginPage.vue";
import ConfigurePage from "@/components/admin/ConfigurePage.vue";
import PlanCreation from "@/components/plan/PlanCreation";
import AddStaff from "@/components/staff/AddStaff";
import StaffList from "@/components/staff/StaffList";
import ResetPassword from "@/components/login/ResetPassword";
import ForgotPassword from "@/components/login/ForgotPassword";

const routesUrl = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { guest: true }
  },
  {
    path: '/forgotpassword',
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: '/resetpassword/:perm',
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AdminDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-staff",
    name: "AddStaff",
    component: AddStaff,
    meta: { requiresAuth: true }
  },
  {
    path: "/staff-list",
    name: "StaffList",
    component: StaffList,
    meta: { requiresAuth: true }
  },
  {
    path: "/plan-creation",
    name: "PlanCreation",
    component: PlanCreation,
    meta: { requiresAuth: true }
  },
  {
    path: "/configure",
    name: "ConfigurePage",
    component: ConfigurePage,
    meta: { requiresAuth: true }
  },
  {
    path: "/user-details/:id",
    name: "UserDetails",
    component: UserDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-new-quotation",
    name: "Send New Quotation",
    component: SendNewQuotation,
    meta: { requiresAuth: true }
  },
  {
    path: "/members-list",
    name: "list",
    component: MemberList,
    meta: { requiresAuth: true }
  },
  {
    path: "/payments",
    name: "Member Payments",
    component: MemberPayments,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routesUrl,
});

// Navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = true; // Set isAuthenticated to true or false based on your logic
  
  console.log('Navigation guard check:', { isAuthenticated });

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    console.log('User not authenticated, redirecting to login.');
    next({ name: 'Login' });
  } else if (to.matched.some(record => record.meta.guest) && isAuthenticated) {
    console.log('Authenticated user attempting to access guest route, redirecting to dashboard.');
    next({ name: 'Dashboard' });
  } else {
    console.log('Proceeding to route.');
    next();
  }
});

export default router;
