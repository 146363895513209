<template>
  <div class="position-sticky">
    <!-- <div class="text-center">
            <img src="../../assets/images/logo.jpg" alt="BBA" />
        </div> -->
    <div class="text-left">
      <h1 class="h3 text-success">FitFat</h1>
    </div>

    <ul class="side-links">
      <li><router-link to="/">Dashboard</router-link></li>

      <!-- Member Section Start -->
      <!-- <h3 class="h6 mt-4 mb-2">Member Management</h3> -->
      <li><router-link to="/members-list">Patient List</router-link></li>
            <!-- Member Section End-->

      <!-- <li><router-link to="#">Pending Payments</router-link></li> -->
      <li><router-link to="/add-medicine">Add Medicine</router-link></li>
      <li><router-link to="/medicine-list">Medicine List</router-link></li>


      
      <!-- <h3 class="h6 mt-4 mb-2">Configure</h3>
      <li><router-link to="/plan-creation">Create Services</router-link></li>
      <li><router-link to="/plan-creation">Broadcast Message</router-link></li> -->


      <!-- <h3 class="h6 mt-4 mb-2">Staff Management</h3>
      <li>
        <router-link to="/add-staff">Add Staff</router-link>
      </li>
      <li>
        <router-link to="/staff-list">Staff List</router-link>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarClient",
};
</script>
