<template>
    <DashboardLayout>
        <div class="w-container mt-5">
          
            <div class="mt-4">
              <div class="display-flex mb-2 border-bottom pb-3">
                  <div class="flex-1">
                    <h2 class="h4">Payments History</h2>
                    
                  </div>
                  <div>
                    <div class="row">
                    <div class="col-xl-6">From<VueDatePicker v-model="date"></VueDatePicker></div>
                    <div class="col-xl-6">To<VueDatePicker v-model="date"></VueDatePicker></div>
                    </div>
                 
                      <!-- <button class="export-btn ms-3" @click="exportAsPdf">Export as PDF <span class="material-icons">
                        print
                        </span></button> -->
                    </div>
              </div>
        
              
           <div class="table-sort"> 
                  <table class="table" id="pdf-table">
                    <thead>
                      <tr>
                          <th scope="col" @click="sort('name')">Member ID 
                            <span class="material-icons">swap_vert</span>
                          </th>
                        <th scope="col" @click="sort('name')">Name
                          <span class="material-icons">swap_vert</span>
                        </th>
                        <th scope="col" @click="sort('name')">Father Name
                          <span class="material-icons">swap_vert</span>
                        </th>
                        <th scope="col" @click="sort('name')">Upline
                          <span class="material-icons">swap_vert</span>
                        </th>
                      
                        <th scope="col" @click="sort('phone')">Mobile No <span class="material-icons">
                          swap_vert
                          </span></th>
                     
                          <th scope="col" @click="sort('doj')">Level <span class="material-icons">
                            swap_vert
                            </span></th>
                            <th scope="col" @click="sort('doj')">Paid Amount <span class="material-icons">
                                swap_vert
                                </span></th>
                          <th scope="col" @click="sort('doj')">Status
                           </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="user in paginatedUsers" :key="user.id">
                         <td>{{ user.id }}</td>
                        <td> 
                          <div class="row">
                            <div class="profile-table">
                            <span>RK</span>
                         </div>
                          {{ user.name }}
                        </div>
                        </td>
                        <td>{{ user.fname }}</td>
                        <td>{{ user.upline }}</td>
                   
                        <td>{{ user.phone }}</td>
                        <td>{{ user.clevel }}</td>
                        <td>₹ {{ user.amount }}</td>
             
                   
                        <td>
                            <span class="badge rounded-pill bg-success">Success</span> 
                            <span class="badge rounded-pill bg-danger">Failed</span>


                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <nav aria-label="Page navigation example ">
                    <ul class="pagination justify-content-end">
                      <li class="page-item" :class="{ disabled: currentPage === 1 }">
                        <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)" tabindex="-1" aria-disabled="currentPage === 1">Previous</a>
                      </li>
                      <!-- Dynamically generate page numbers based on totalPages -->
                      <li class="page-item" :class="{ active_page: n === currentPage }" v-for="n in totalPages" :key="n">
                        <a class="page-link" href="#" @click.prevent="changePage(n)">{{ n }}</a>
                      </li>
                      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                        <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Next</a>
                      </li>
                      
                    </ul>
                  </nav>
                  
    
                </div>
          </div>
    
          </div>
        </DashboardLayout>
        </template>
    <script>
    import DashboardLayout from "../dashboard/DashboardLayout.vue";
    import { ref, computed } from 'vue';
    import { jsPDF } from 'jspdf';
    import html2canvas from 'html2canvas';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    export default{
        name : 'MemberPayments',
        components:{DashboardLayout,VueDatePicker},
        setup() {
        
          const currentPage = ref(1);
          const itemsPerPage = ref(2);
        const searchQuery = ref('');
        const sortKey = ref('');
        const sortAsc = ref(true);
        const users = ref([
          { id: 'BB-231648',
          name: 'Rohit Kumar', 
          fname: 'Sunny Sharma',
          upline: "Manish Goyal",
          phone: '7009123000', 
          amount: '4000',
          clevel: 'new'  },
          { id: 'BB-251648',
          name: 'Anil Kumar', 
          fname: 'Sharn Sharma',
          upline: "Harman Singh",
          phone: '7009123XXXX', 
          amount: '3000',
          clevel: 'Level 1'  },
         
        
        ]);
        const paginatedUsers = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredUsers.value.slice(start, end);
    });
    
    const totalPages = computed(() => {
      return Math.ceil(filteredUsers.value.length / itemsPerPage.value);
    });
    
    function changePage(page) {
      if (page < 1) {
        currentPage.value = 1;
      } else if (page > totalPages.value) {
        currentPage.value = totalPages.value;
      } else {
        currentPage.value = page;
      }
    }
    
    
    
    
        async function exportAsPdf() {
          const pdf = new jsPDF();
    
          const element = document.getElementById('pdf-table'); // Ensure your table has this ID
          const canvas = await html2canvas(element);
          const imgData = canvas.toDataURL('image/png');
    
          const imgProps= pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save('table.pdf');
        }
    
        const sortedUsers = computed(() => {
          return [...users.value].sort((a, b) => {
            if (sortKey.value) {
              const aValue = a[sortKey.value];
              const bValue = b[sortKey.value];
    
              if (sortAsc.value) {
                return aValue.localeCompare(bValue, undefined, { numeric: true });
              } else {
                return bValue.localeCompare(aValue, undefined, { numeric: true });
              }
            }
            return 0;
          });
        });
    
        const filteredUsers = computed(() => {
          return sortedUsers.value.filter((user) =>
            Object.values(user).some((field) =>
              field.toString().toLowerCase().includes(searchQuery.value.toLowerCase())
            )
          );
        });
    
        function sort(key) {
          if (sortKey.value === key) {
            sortAsc.value = !sortAsc.value;
          } else {
            sortAsc.value = true;
            sortKey.value = key;
          }
        }
    
        return { users, searchQuery, filteredUsers, sort , exportAsPdf, currentPage, itemsPerPage, totalPages, paginatedUsers, changePage , };
      },
    }
    </script>