import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./assets/CustomStyle.css";
import router from "../src/router/index";
import globalMixin from './mixins/globalMixin';


// Create the app
const app = createApp(App);

// Register the global mixin
app.mixin(globalMixin);

// Use the router
app.use(router);

// Mount the app
app.mount('#app');