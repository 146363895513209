<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <h2 class="h5 border-bottom pb-3">Patient List</h2>
      <div class="mt-4">
        <div class="display-flex mb-2">
          <div class="flex-1">
            <ul class="level-tabs">
              <li>
                <span class="badge rounded-pill active-level">
                  All Patients
                </span>
              </li>
              <li>
                <span class="badge rounded-pill bg-secondary">
                  Met the patients today
                </span>
              </li>
            </ul>
          </div>
          <div>
            <input
              type="search"
              class="search-form-control"
              placeholder="Search..."
              v-model="searchQuery"
            />
            <button class="export-btn ms-3" @click="exportAsPdf">
              Export as PDF <span class="material-icons"> print </span>
            </button>
            <button class="export-btn ms-1" @click="exportAsExcel">
              Export to Excel <span class="material-icons"> file_download </span>
            </button>
          </div>
        </div>

        <div class="table-sort my-table-2323">
          <table class="table" id="pdf-table">
            <thead>
              <tr>
                <th>UHID</th>
                <th>Patient Name</th>
                <th>Father Name</th>
                <th>Phone No.</th>
                <th>Age/Sex</th>
                <th>Address</th>
                <th>Appointed For</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in paginatedUsers" :key="user.patient_id">
                <td>{{ user.patient_id }}</td>
                <td>
                  <div class="display-flex">
                    <div class="profile"><span>{{ getUserInitials(user.patientName) }}</span></div>
                    <div>
                      <strong>{{ user.patientName }}</strong>
                      <span class="nm-phone">Last Visit - {{ formatDate(user.last_visit) }}</span>
                    </div>
                  </div>
                </td>
                <td>{{ user.fatherName }}</td>
                <td>{{ user.phoneNumber }}</td>
                <td>{{ user.age }}/{{ user.gender }}</td>
                <td>{{ user.address }}</td>
                <td>-</td>
                <td class="text-center">
                  <i class="fab fa-whatsapp gtw" @click="sendWhatsAppMessage(user)"></i>
                  <button class="btn btn-primary" @click="navigateToMemberDetails(user.patient_id)">View</button>&nbsp;
                  <button class="btn btn-danger" @click="confirmDelete(user.patient_id)">Delete</button>
                </td>
              </tr>
              <tr v-if="paginatedUsers.length === 0">
                <td colspan="10" class="text-center">No record found</td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="changePage(currentPage - 1)"
                  tabindex="-1"
                  aria-disabled="currentPage === 1"
                ><span class="material-icons">chevron_left</span></a>
              </li>
              <li v-for="n in paginationRange" :key="n" class="page-item" :class="{ active: n === currentPage }">
                <a class="page-link" href="#" @click.prevent="changePage(n)">{{ n }}</a>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="changePage(currentPage + 1)"
                ><span class="material-icons">chevron_right</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Confirm Deletion</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete this patient record?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="deletePatientRecord">Delete Patient Record</button>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import '@fortawesome/fontawesome-free/css/all.css';
import { ref, computed, onMounted } from "vue";
import API from "@/services/api";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { useRouter } from 'vue-router';
import { Modal } from 'bootstrap'; // Import Modal directly from bootstrap

export default {
  name: "PatientList",
  components: { DashboardLayout },
  setup() {
    const currentPage = ref(1);
    const itemsPerPage = ref(20); // Display 20 records per page
    const searchQuery = ref("");
    const users = ref([]);
    const deleteUser = ref(null); // Store the user ID to be deleted
    const router = useRouter(); // Initialize router

    const navigateToMemberDetails = (userId) => {
      router.push({ name: 'UserDetails', params: { id: userId } });
    };

    onMounted(() => {
      fetchUsers();
    });

    const fetchUsers = async () => {
      try {
        const response = await API.getAllPatients();
        users.value = response.data.patients;
        console.log(users.value); // Debugging to check the fetched data
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    const filteredUsers = computed(() => {
      if (!searchQuery.value) {
        return users.value;
      }
      return users.value.filter((user) => {
        return Object.values(user).some((field) => {
          if (field) {
            return field
              .toString()
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase());
          }
        });
      });
    });

    const paginatedUsers = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredUsers.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredUsers.value.length / itemsPerPage.value);
    });

    const paginationRange = computed(() => {
      const range = [];
      const rangeSize = 2;
      const total = totalPages.value;

      for (let i = 1; i <= total; i++) {
        if (
          i === 1 ||
          i === total ||
          (i >= currentPage.value - rangeSize && i <= currentPage.value + rangeSize)
        ) {
          range.push(i);
        } else if (
          i === currentPage.value - rangeSize - 1 ||
          i === currentPage.value + rangeSize + 1
        ) {
          range.push('...');
        }
      }
      return range.filter((item, pos, arr) => arr.indexOf(item) === pos);
    });

    function changePage(page) {
      if (page < 1) {
        currentPage.value = 1;
      } else if (page > totalPages.value) {
        currentPage.value = totalPages.value;
      } else {
        currentPage.value = page;
      }
    }

    async function exportAsPdf() {
      const pdf = new jsPDF();
      const element = document.getElementById("pdf-table");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("table.pdf");
    }

    async function exportAsExcel() {
      const data = filteredUsers.value.map(user => ({
        'UHID': user.patient_id,
        'Name': user.patientName,
        'Father Name': user.fatherName,
        'Phone': user.phoneNumber,
        'Age': user.age,
        'Gender': user.gender,
        'Address': user.address
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Patient List");

      XLSX.writeFile(workbook, "patient_list.xlsx");
    }

    function getUserInitials(name) {
      if (!name) return '';
      const names = name.split(' ');
      const firstName = names[0];
      const lastName = names.length > 1 ? names[names.length - 1] : '';
      return lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : `${firstName.charAt(0)}`;
    }

    function formatDate(dateString) {
      if (!dateString) return '-';
      return format(new Date(dateString), 'MMM dd, yyyy - hh:mm a');
    }

    function sendWhatsAppMessage(user) {
      const patientName = user.patientName;
      const templateMessage = `Hi *${patientName}*,

Welcome to Amaziocraft

Thank you for showing interest in our services/products. We are excited to assist you with [brief mention of services/products].

To get started, could you please provide us with a bit more information about what you’re looking for? This will help us to tailor our recommendations and provide you with the best possible service.

Feel free to ask any questions you may have. We’re here to help!

Best regards,
*Dharmpreet Singh*
Marketing Manager`;

      const whatsappUrl = `https://wa.me/${user.phoneNumber}?text=${encodeURIComponent(templateMessage)}`;
      window.open(whatsappUrl, '_blank');
    }

    const confirmDelete = (userId) => {
      deleteUser.value = userId;
      const modalElement = document.getElementById('deleteModal');
      const modal = new Modal(modalElement);
      modal.show();
    };

    const deletePatientRecord = async () => {
      try {
        await API.deletePatientDetails(deleteUser.value);
        fetchUsers(); // Refresh the user list
        const modalElement = document.getElementById('deleteModal');
        const modal = Modal.getInstance(modalElement);
        modal.hide();
      } catch (error) {
        console.error('Failed to delete patient record:', error);
      }
    };

    return {
      searchQuery,
      exportAsPdf,
      exportAsExcel,
      currentPage,
      itemsPerPage,
      totalPages,
      paginatedUsers,
      changePage,
      formatDate,
      fetchUsers,
      paginationRange,
      getUserInitials,
      sendWhatsAppMessage,
      navigateToMemberDetails,
      confirmDelete,
      deletePatientRecord
    };
  },
};
</script>

<style scoped>
.spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
