<template>
    <DashboardLayout>
        <div class="w-container mt-5">
          
            <div class="head-section border-bottom pb-3">
              <h3 class="h4 flex-v-center"><span class="material-icons">
                settings
                </span> Configure</h3>
              <router-link to="/members-list">Members List</router-link>
            </div>
            <div class="mt-4">        
              <div class="table-sort border-l"> 
                  <div class="row">
                    <div class="col-xl-7">
                      <form class="form-style-1">
                        <div class="row">
                          <div class="form-input-wrap">
                            <label for="spID" class="form-label">Sponsor ID <span>*</span></label>
                            <input type="text" class="form-control" id="spID" value="BTT2323" readonly>
                          </div>
                          <div class="form-input-wrap">
                            <label for="epin" class="form-label">E-Pin <span>*</span></label>
                            <select class="form-select" aria-label="Default select example">
                              <option value="" selected>--Choose---</option>
                              <option value="BBA111">BBA111</option>
                            </select>
                            <!-- <input type="text" class="form-control" id="epin" name="epin" required> -->
                          </div>
                          <!-- <div class="form-input-wrap">
                            <label for="fatherName" class="form-label">Father Name</label>
                            <input type="text" class="form-control" id="fatherName" >
                          </div> -->
                          <div class="form-input-wrap">
                              <label for="mName" class="form-label">Name <span>*</span></label>
                              <input type="text" class="form-control" id="mName" name="name" required>
                          </div>
                         
                          <div class="form-input-wrap">
                            <label for="mobileNo" class="form-label">Mobile No. <span>*</span></label>
                            <input type="text" class="form-control" id="mobileNo" name="mobile_no" required="">
                          </div>
                     
                          <div class="form-input-wrap">
                            <label for="panCard" class="form-label">PAN Card</label>
                            <input type="text" class="form-control" id="panCard" name="pan_card">
                          </div>
                          <div class="form-input-wrap">
                            <label for="aadharCard" class="form-label">Aadhar Card</label>
                            <input type="text" class="form-control" id="aadharCard" name="aadhar_card" >
                          </div>
                          <div class="width100">
                            <label for="address" class="form-label">Address</label>
                            <textarea class="form-control" id="address" rows="2" name="address"></textarea>
                          </div>
                          <div class="mt-3">
                            <input type="submit" value="Save Member Details" class="p-btn">
                            <input type="reset" value="Cancel" class="s-btn ms-3">
                          </div>
                          </div>
                      </form>
                    </div>
                    <div class="col-xl-5">
                     Test

                    </div>
                  </div>
                </div>
              </div>
            </div>
        </DashboardLayout>
        </template>
    <script>
    import DashboardLayout from "../dashboard/DashboardLayout.vue";
    export default{
        name : 'ConfigurePage',
        components:{DashboardLayout},
    }
    </script>