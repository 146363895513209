<template>
  <div class="w-container py-3">
    <div class="d-flex align-items-center">
      <div class="lf-t-header d-flex align-items-center">
        <span class="badge bg-light text-dark">
          <span class="material-icons"> reorder </span>
        </span>
        &nbsp;&nbsp;
        <router-link to="/send-new-quotation" class="badge rounded-pill bba-bg-primary p-2 link-deco">
          Add New Patient/OPD <i class="bi bi-plus-lg"></i>
        </router-link>
      </div>

      <div class="right-header">
        <ul class="rt-links">
          <li class="nav-item dropdown">
            <a
              class="dropdown-toggle btn-profile text-link"
              id="navbarScrollingDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="profile">
                <span>JD</span>
              </div>
              {{ name }}
            </a>

            <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
              <li><a class="dropdown-item" href="#">Change Password</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" @click.prevent="logout">Log Out</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/services/api';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
  name: "TopHeader",
  methods: {
    async logout() {
      try {
        await API.logout(); // Call the logout API endpoint
        // Clear local storage
        localStorage.removeItem('user_id');
        localStorage.removeItem('email');
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('phone');
        localStorage.removeItem('role');
        // Redirect to login page with a logout success message
        this.$router.push({ name: 'Login', query: { message: 'Logout successful' } });
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
  }
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
